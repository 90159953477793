<template>
  <div>
    <div class="withdrawal-balance">
    <p>可提现余额(元)</p>
    <b>{{amount}}</b>
  </div>

  <div class="operation-panel">
    <p>提现金额</p>
    <van-field
      v-model="money"
      center
      clearable
      type="number"
      placeholder="请输入可提现金额"
    >
      <template #button>
       <div class="all-withdrawal-btn" @click="allWithdrawalBtn">全部提现</div>
      </template>
    </van-field>

    <div class="bind-alipay">
      <span>提现账户</span>
      <span><div v-if="aliAccount">{{aliAccount}}</div><div @click="bindAlipay" v-if="!aliAccount">绑定支付宝账号 <van-icon name="arrow" /></div></span>
    </div>

    <div class="withdrawal-btn" @click="toAlipay">提现</div>
      <van-divider class="register">
        <div class="register-btn" v-on:click="$router.push('/withdrawalList')">提现记录</div>
      </van-divider>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Withdrawal',
  data(){
    return{
      amount:0.00,
      money:'',
      aliAccount:""
    }
  },
  created(){
    this.amount = this.$route.params.amount;
    this.init();
  },
  methods:{
    toAlipay(){
      if(this.money==''){
          this.$toast('提现余额不能为空!');
          return;
      }
      console.log(this.money);
      console.log(this.amount);
      if(parseFloat(this.money)>parseFloat(this.amount)){
          this.$toast('提现余额不足!');
          return;
      }
      // if(this.money<10){
      //     this.$toast('满10元可提现!');
      //     return;
      // }
      if(!this.aliAccount){
        this.$toast('请点击`绑定支付宝账号`上传账号!');
        return;
      }
      this.axios.post('/app/withdrawal', {
          balance:this.money,
          account:this.aliAccount
          }).then((response) => {
            console.log(response);
            if(response==null){
              this.$toast.success("恭喜您，提现申请成功,请等待审核!");
              this.$router.back();
            }
            
              // this.slowList = response;
          }).catch(function (error) {
              console.log(error);
          });
      // this.$toast('线上提现功能申请中!');
      
    },
    bindAlipay(){
      this.$router.push({path: '/BindAlipay'});
    },
    allWithdrawalBtn(){
      this.money=this.amount;
    },
    init(){
        this.axios.post('/app/userInfo', {
          }).then((response) => {
            console.log(response);
            this.aliAccount = response.aliAccount;
              // this.slowList = response;
          }).catch(function (error) {
              console.log(error);
          });
    }
  }
}
</script>

<style scoped>
  .withdrawal-balance {
    width: 100%;
    height: 200px;
    position: relative;
    z-index: -1;
    overflow: hidden;
    color: #251a1a;
  }
  .withdrawal-balance p,b{
    margin: 15px;
  }
  .withdrawal-balance::after {
    content: '';
    width: 140%;
    height: 200px;
    position: absolute;
    left: -20%;
    top: 0;
    z-index: -1;
    border-radius: 0 0 50% 50%;
    background: #f5f5f5;
  }
  .withdrawal-balance b{
    font-size: 30px;
  }
  .operation-panel{
    padding: 5px 15px;
    border-radius: 10px;
    margin: -90px 4% auto 4%;
    background: #FFFFFF;
  }
  .operation-panel .van-field{
    border: 1px solid #cccccc;
  }
  .bind-alipay{
    background: #F8F9FD;
    margin: 4% 0;
    padding: 13px 10px;
  }
  .bind-alipay span:last-of-type{
    float: right;
    color: #999999;
  }
  .withdrawal-btn{
    width: 95%;
    height: 45px;
    line-height: 45px;
    margin: 5px auto 20px;
    text-align: center;
    color: #FFFFFF;
    background: #00ef83;
    border-radius: 5px;
  }
  .all-withdrawal-btn{
    color: #777777;
  }
</style>
